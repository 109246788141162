.join {
    display: flex;
    padding: 0 2rem;
    gap: 10rem;
}

.left {
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}

.left>hr {
    position: absolute;
    width: 10.5rem;
    border: 2px solid var(--orange);
    border-radius: 20%;
    margin: -10px 0;
}

.left>div {
    display: flex;
    gap: 1rem;
}

.right {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.emailContainer {
    display: flex;
    gap: 3rem;
    background-color: gray;
    padding: 1rem 2rem;

}

.emailContainer>input {
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--lightgrayz);

}

::placeholder {
    color: var(--lightgrayz);
}

.btnJ {
    background-color: var(--orange) !important;
    color: white;
}


@media screen and (max-width: 768px) {
    .join {
        flex-direction: column;
        gap: 1rem;
    }

    .left {
        font-size: x-large;
        flex-direction: column;
    }

    .right {
        padding: 2rem;
    }
}